import { DataTable, Modal, TableData } from "@shopify/polaris";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import React from "react";
import styled from "styled-components";

import { updateShowBundleItems } from "@vendor-app/app/_state/actionCreators/subscriptionDetails";
import { useVendorPortalVariantShopifyIdToPurchasableMap } from "@vendor-app/app/_state/reducers/purchasables";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { LineItemDisplay } from "../../components/LineItemDisplay";

const ShowBundleContentModalInfoContainer = styled("div")`
  height: 580px;

  & .Polaris-DataTable__Table thead th {
    background-color: #fafbfb;
    color: #6d7175;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding: 10px 30px 10px 20px;
  }

  & .Polaris-DataTable__Table thead th:first-child {
    width: 80%;
  }

  & .Polaris-DataTable__Table th:nth-child(2) {
    text-align: center;
  }

  & .Polaris-DataTable__Table td {
    text-align: center;
    vertical-align: middle;
  }

  & .Polaris-DataTable__Table tbody tr {
    border: none;
    box-shadow: 0px -1px 0px 0px #e1e3e5 inset;
  }
`;

export const ShowBundleContentModal = () => {
  const variantToPurchasableMap = useVendorPortalVariantShopifyIdToPurchasableMap();
  const bundleInfo = useSmartrrVendorSelector(state => state.subscriptionDetails.showBundleContentsModal);

  const line = bundleInfo.lineItem;

  const dispatch = useSmartrrVendorDispatch();

  const rows: TableData[][] = [];
  line?.bundleInfo.lines?.forEach(line => {
    const purchasable: IPurchasable | undefined = variantToPurchasableMap[line.id];
    const vnt = purchasable?.vnts?.find(variant => {
      return variant.shopifyId && shopifyGidToNumber(variant.shopifyId) === Number(line.id);
    });
    const purchasableName = purchasable?.purchasableName;
    const variantName = vnt?.purchasableVariantName;

    rows.push([
      <LineItemDisplay
        key={line.id}
        purchasable={purchasable}
        vnt={vnt}
        productName={purchasableName}
        variantName={variantName}
        isAddOn={false}
        isRedeemed={false}
        isBundle={false}
      />,
      line.quantity,
    ]);
  });

  return (
    <Modal
      title={`${line?.productName} - ${line?.variantName}`}
      open={bundleInfo.showModal}
      onClose={() => dispatch(updateShowBundleItems(false))}
    >
      <ShowBundleContentModalInfoContainer>
        <DataTable columnContentTypes={["text", "numeric"]} headings={["Products", "Quantity"]} rows={rows} />
      </ShowBundleContentModalInfoContainer>
    </Modal>
  );
};
