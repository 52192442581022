import { LegacyCard, LegacyStack, Modal, Text, Thumbnail } from "@shopify/polaris";
import { IPurchaseStateLineItem } from "@smartrr/shared/entities/PurchaseState/CustomerPurchaseLineItem";
import React from "react";
import styled from "styled-components";

import { useVendorPortalVariantToPurchasableMap } from "@vendor-app/app/_state/reducers/purchasables";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

const InvalidVariantCardContainer = styled.div`
  .Polaris-LegacyCard {
    background-color: #fff4f4;
    border: 1px solid #fd5749;
  }
`;

interface Props {
  lineItem: IPurchaseStateLineItem;

  onReplaceVariantClick(): void;
  onCancelSubscription(): void;
  onClose(): void;
}

export function InvalidVariantModal({
  onReplaceVariantClick,
  onCancelSubscription,
  onClose,
  lineItem,
}: Props): JSX.Element {
  const variantToPurchasableMap = useVendorPortalVariantToPurchasableMap();
  const open = useSmartrrVendorSelector(state => state.subscriptionDetails.showInvalidVariant);

  const purchasable = lineItem.vnt?.id ? variantToPurchasableMap[lineItem.vnt.id] : undefined;
  const imageUrl = lineItem.vnt?.purchasableVariantImages?.[0] || purchasable?.purchasableImages?.[0];

  return (
    <Modal
      sectioned
      title="Subscription contains invalid variant"
      open={open}
      onClose={onClose}
      primaryAction={{
        id: "subscription-invalid-variant__confirm-replace-btn",
        content: "Replace variant",
        onAction: onReplaceVariantClick,
      }}
      secondaryActions={[
        {
          id: "subscription-invalid-variant__cancel-replace-btn",
          content: "Cancel subscription",
          onAction: onCancelSubscription,
          destructive: true,
          plain: true,
        },
      ]}
    >
      <InvalidVariantCardContainer>
        <LegacyCard sectioned>
          {!!imageUrl && <Thumbnail source={imageUrl} alt={purchasable?.purchasableName ?? "Variant"} />}
          <LegacyStack vertical spacing="extraTight">
            <Text variant="bodyMd" as="p" id="subscription-invalid-variant__product-title">
              {purchasable?.purchasableName ?? "Removed product"}
            </Text>
            {lineItem.vnt?.purchasableVariantName ? (
              <Text variant="bodySm" as="p" id="subscription-invalid-variant__variant-title">
                {lineItem.vnt.purchasableVariantName}
              </Text>
            ) : null}
          </LegacyStack>
        </LegacyCard>
      </InvalidVariantCardContainer>
    </Modal>
  );
}
