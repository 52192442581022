import type { SubscriptionDetailsLineItem } from "@smartrr/shared/interfaces/lineitem/api";
import { SmartrrAction } from "@vendor-app/app/_state/typedVendorRedux";

export const updateRawContractsModal = (
  showModal: boolean
): SmartrrAction<"SUBSCRIPTION_DETAILS_SHOW_RAW_CONTRACTS" | "SUBSCRIPTION_DETAILS_HIDE_RAW_CONTRACTS"> => {
  if (showModal) {
    return {
      type: "SUBSCRIPTION_DETAILS_SHOW_RAW_CONTRACTS",
      payload: undefined,
    };
  }
  return {
    type: "SUBSCRIPTION_DETAILS_HIDE_RAW_CONTRACTS",
    payload: undefined,
  };
};

export const updateCancelSubscriptionModal = (
  showModal: boolean
): SmartrrAction<
  "SUBSCRIPTION_DETAILS_SHOW_CANCEL_SUBSCRIPTION" | "SUBSCRIPTION_DETAILS_HIDE_CANCEL_SUBSCRIPTION"
> => {
  if (showModal) {
    return {
      type: "SUBSCRIPTION_DETAILS_SHOW_CANCEL_SUBSCRIPTION",
      payload: undefined,
    };
  }
  return {
    type: "SUBSCRIPTION_DETAILS_HIDE_CANCEL_SUBSCRIPTION",
    payload: undefined,
  };
};

export const updateInvalidVariantModal = (
  showModal: boolean
): SmartrrAction<"SUBSCRIPTION_DETAILS_SHOW_INVALID_VARIANT" | "SUBSCRIPTION_DETAILS_HIDE_INVALID_VARIANT"> => {
  if (showModal) {
    return {
      type: "SUBSCRIPTION_DETAILS_SHOW_INVALID_VARIANT",
      payload: undefined,
    };
  }
  return {
    type: "SUBSCRIPTION_DETAILS_HIDE_INVALID_VARIANT",
    payload: undefined,
  };
};

export const updateReplaceVariantModal = (
  showModal: boolean
): SmartrrAction<"SUBSCRIPTION_DETAILS_SHOW_REPLACE_VARIANT" | "SUBSCRIPTION_DETAILS_HIDE_REPLACE_VARIANT"> => {
  if (showModal) {
    return {
      type: "SUBSCRIPTION_DETAILS_SHOW_REPLACE_VARIANT",
      payload: undefined,
    };
  }
  return {
    type: "SUBSCRIPTION_DETAILS_HIDE_REPLACE_VARIANT",
    payload: undefined,
  };
};

export const updateShowBundleItems = (
  showModal: boolean,
  lineItem?: SubscriptionDetailsLineItem
): SmartrrAction<"SUBSCRIPTION_DETAILS_SHOW_BUNDLE_CONTENT" | "SUBSCRIPTION_DETAILS_HIDE_BUNDLE_CONTENT"> => {
  if (showModal && lineItem) {
    return {
      type: "SUBSCRIPTION_DETAILS_SHOW_BUNDLE_CONTENT",
      payload: {
        lineItem,
      },
    };
  }
  return {
    type: "SUBSCRIPTION_DETAILS_HIDE_BUNDLE_CONTENT",
    payload: undefined,
  };
};
export const updateShippingPriceModal = (
  showModal: boolean
): SmartrrAction<"SUBSCRIPTION_DETAILS_SHOW_EDIT_SHIPPING" | "SUBSCRIPTION_DETAILS_HIDE_EDIT_SHIPPING"> => {
  if (showModal) {
    return {
      type: "SUBSCRIPTION_DETAILS_SHOW_EDIT_SHIPPING",
      payload: undefined,
    };
  }
  return {
    type: "SUBSCRIPTION_DETAILS_HIDE_EDIT_SHIPPING",
    payload: undefined,
  };
};

export const updateShowSetUnpauseDate = (
  showModal: boolean
): SmartrrAction<"SUBSCRIPTION_DETAILS_SHOW_SET_PAUSE_DATE" | "SUBSCRIPTION_DETAILS_HIDE_SET_PAUSE_DATE"> => {
  if (showModal) {
    return {
      type: "SUBSCRIPTION_DETAILS_SHOW_SET_PAUSE_DATE",
      payload: undefined,
    };
  }
  return {
    type: "SUBSCRIPTION_DETAILS_HIDE_SET_PAUSE_DATE",
    payload: undefined,
  };
};

export const updateAddItemModal = (
  showModal: boolean
): SmartrrAction<"SUBSCRIPTION_DETAILS_SHOW_ADD_ITEM" | "SUBSCRIPTION_DETAILS_HIDE_ADD_ITEM"> => {
  if (showModal) {
    return {
      type: "SUBSCRIPTION_DETAILS_SHOW_ADD_ITEM",
      payload: undefined,
    };
  }
  return {
    type: "SUBSCRIPTION_DETAILS_HIDE_ADD_ITEM",
    payload: undefined,
  };
};

export const updateSwapItemModal = (
  showModal: boolean
): SmartrrAction<"SUBSCRIPTION_DETAILS_SHOW_SWAP_ITEM" | "SUBSCRIPTION_DETAILS_HIDE_SWAP_ITEM"> => {
  if (showModal) {
    return {
      type: "SUBSCRIPTION_DETAILS_SHOW_SWAP_ITEM",
      payload: undefined,
    };
  }
  return {
    type: "SUBSCRIPTION_DETAILS_HIDE_SWAP_ITEM",
    payload: undefined,
  };
};

export const updateRemoveAllProductsWarningModal = (
  showModal: boolean
): SmartrrAction<
  | "SUBSCRIPTION_DETAILS_SHOW_REMOVE_ALL_PRODUCTS_WARNING"
  | "SUBSCRIPTION_DETAILS_HIDE_REMOVE_ALL_PRODUCTS_WARNING"
> => {
  if (showModal) {
    return {
      type: "SUBSCRIPTION_DETAILS_SHOW_REMOVE_ALL_PRODUCTS_WARNING",
      payload: undefined,
    };
  }
  return {
    type: "SUBSCRIPTION_DETAILS_HIDE_REMOVE_ALL_PRODUCTS_WARNING",
    payload: undefined,
  };
};

export const updateRemoveSubscriptionDiscountModal = (
  showModal: boolean
): SmartrrAction<
  | "SUBSCRIPTION_DETAILS_SHOW_REMOVE_SUBSCRIPTION_DISCOUNT"
  | "SUBSCRIPTION_DETAILS_HIDE_REMOVE_SUBSCRIPTION_DISCOUNT"
> => {
  if (showModal) {
    return {
      type: "SUBSCRIPTION_DETAILS_SHOW_REMOVE_SUBSCRIPTION_DISCOUNT",
      payload: undefined,
    };
  }
  return {
    type: "SUBSCRIPTION_DETAILS_HIDE_REMOVE_SUBSCRIPTION_DISCOUNT",
    payload: undefined,
  };
};
export const updateEditNoteModal = (
  showModal: boolean
): SmartrrAction<"SUBSCRIPTION_DETAILS_SHOW_EDIT_NOTE" | "SUBSCRIPTION_DETAILS_HIDE_EDIT_NOTE"> => {
  if (showModal) {
    return {
      type: "SUBSCRIPTION_DETAILS_SHOW_EDIT_NOTE",
      payload: undefined,
    };
  }
  return {
    type: "SUBSCRIPTION_DETAILS_HIDE_EDIT_NOTE",
    payload: undefined,
  };
};
