import { Modal, OptionList, TextField, Thumbnail } from "@shopify/polaris";
import { OptionDescriptor } from "@shopify/polaris/build/ts/src/types";
import { DEFAULT_VARIANT_TITLE, NO_OP_CALLBACK } from "@smartrr/shared/constants";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { IPurchaseStateLineItem } from "@smartrr/shared/entities/PurchaseState/CustomerPurchaseLineItem";
import { ISmartrrSellingPlanGroup } from "@smartrr/shared/entities/SellingPlanGroup";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import { getVariantImageFromPurchasableMap } from "@smartrr/shared/utils/useVariantToPurchasableMap";
import { isVariantInStock } from "@smartrr/shared/utils/variants";
import { flatten } from "lodash";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import {
  updateInvalidVariantModal,
  updateReplaceVariantModal,
} from "@vendor-app/app/_state/actionCreators/subscriptionDetails";
import { useVendorPortalVariantToPurchasableMap } from "@vendor-app/app/_state/reducers/purchasables";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { useSwapItems } from "../../components/SubscriptionTabs/hooks/useSwapItems";

const FixedSearch = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #c6cdd4;
  position: sticky;
  top: 0;
  z-index: 10;
`;

interface IHandleInvalidVariantProps {
  customerPurchaseState: IPurchaseStateWithCustomerRelationship | undefined;
  lineItemWithDeletedVariant: IPurchaseStateLineItem | null;
  sellingPlanGroup: ISmartrrSellingPlanGroup | undefined;
}

export const HandleReplaceVariantModal = ({
  customerPurchaseState,
  lineItemWithDeletedVariant,
  sellingPlanGroup,
}: IHandleInvalidVariantProps) => {
  const isModalOpen = useSmartrrVendorSelector(state => state.subscriptionDetails.showReplaceVariantConfirmation);
  const dispatch = useSmartrrVendorDispatch();
  const purchasables = useSmartrrVendorSelector(state => state.purchasables.purchasables);

  const onSwapItems = useSwapItems(customerPurchaseState!, NO_OP_CALLBACK, () =>
    dispatch(updateReplaceVariantModal(false))
  );

  const [filteredPurchasablesSearchText, setFilteredPurchasablesSearchText] = useState<string>("");

  const [modalReplacementVariantId, setModalReplacementVariantId] = useState<string | undefined>();

  const variantToPurchasableMap = useVendorPortalVariantToPurchasableMap();

  const purchasablesForReplacementOptions = useMemo(() => {
    const variantOptions: OptionDescriptor[] = [];
    for (const prev of flatten(purchasables.map(p => (p.vnts || []).map(v => ({ ...v, purchasable: p }))))
      .sort()
      .filter(v => v.shopifyId && v.isActiveInShopify && !v.isDraftOrArchived && isVariantInStock(v))
      .filter(
        v =>
          v.purchasable.purchasableName
            .toLocaleLowerCase()
            .includes(filteredPurchasablesSearchText.toLocaleLowerCase()) ||
          v.purchasableVariantName
            ?.toLocaleLowerCase()
            .includes(filteredPurchasablesSearchText.toLocaleLowerCase())
      )
      .filter(v => sellingPlanGroup?.variantIds.includes(shopifyGidToNumber(v.shopifyId!)))) {
      const imageUrl = getVariantImageFromPurchasableMap(variantToPurchasableMap, prev);
      const label = `${prev.purchasable.purchasableName}${
        prev.purchasableVariantName === DEFAULT_VARIANT_TITLE ? "" : ` - ${prev.purchasableVariantName}`
      }${prev.isActiveInShopify ? "" : " (Deleted)"}`;

      const result: OptionDescriptor = {
        label,
        value: prev.id,
      };

      if (imageUrl) {
        result.media = <Thumbnail size="small" source={imageUrl} alt={label} />;
      }

      variantOptions.push(result);
    }
    return variantOptions;
  }, [purchasables.length, filteredPurchasablesSearchText, sellingPlanGroup]);

  return (
    <Modal
      title="Replace invalid variant"
      open={isModalOpen}
      onClose={() => {
        dispatch(updateReplaceVariantModal(false));
        if (lineItemWithDeletedVariant) {
          dispatch(updateInvalidVariantModal(true));
        }
      }}
      primaryAction={{
        content: "Replace",
        onAction: () => onSwapItems(lineItemWithDeletedVariant?.id, modalReplacementVariantId),
      }}
    >
      <Modal.Section>
        <FixedSearch>
          <TextField
            autoComplete="off"
            label=""
            placeholder="Search products"
            value={filteredPurchasablesSearchText}
            onChange={text => {
              setFilteredPurchasablesSearchText(text);
            }}
          />
        </FixedSearch>
      </Modal.Section>
      <Modal.Section flush>
        <OptionList
          selected={modalReplacementVariantId ? [modalReplacementVariantId] : []}
          verticalAlign="center"
          options={purchasablesForReplacementOptions}
          onChange={value => {
            setModalReplacementVariantId(value[0]);
          }}
        />
      </Modal.Section>
    </Modal>
  );
};
