import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { useCallback } from "react";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { swapSubscriptionLineItem } from "@vendor-app/app/_state/actionCreators/customerPurchaseState";
import { useSmartrrVendorDispatch } from "@vendor-app/app/_state/typedVendorReduxHooks";

export const useSwapItems = (
  customerPurchaseState: IPurchaseStateWithCustomerRelationship,
  onCommitStart?: () => void,
  onCommitEnd?: () => void
) => {
  const dispatch = useSmartrrVendorDispatch();
  const { addToast } = useToast();

  return useCallback(
    async (itemToReplaceId?: string, itemForReplacementId?: string) => {
      if (
        !customerPurchaseState ||
        !customerPurchaseState.custRel ||
        !itemForReplacementId ||
        !itemToReplaceId ||
        !customerPurchaseState?.sellingPlanId
      ) {
        addToast("Failed to replace the item");
        return;
      }

      const lineItem = customerPurchaseState.stLineItems.find(item => item.id === itemToReplaceId);

      onCommitStart && onCommitStart();
      await dispatch(
        swapSubscriptionLineItem({
          customerPurchaseStateId: customerPurchaseState.id,
          customerRelationshipId: customerPurchaseState.custRel?.id,
          variantId: itemForReplacementId,
          quantity: 1,
          sellingPlanId: customerPurchaseState.sellingPlanId,
          lineItemId: itemToReplaceId,
          skdIdx: lineItem?.isAddOn ? lineItem?.skdIdx : undefined,
        })
      ).then(res => {
        if (res.type === "SWAPPED_PURCHASE_STATE_LINE_ITEM") {
          addToast("Item swapped");
          return;
        }
        if (res.type === "ERROR_SWAPPING_PURCHASE_STATE_LINE_ITEM") {
          addToast("Error swapping item", true);
        }
      });
      onCommitEnd && onCommitEnd();
    },
    [customerPurchaseState]
  );
};
