import { LegacyStack, Link, Text, Thumbnail } from "@shopify/polaris";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { IPurchasableVariant } from "@smartrr/shared/entities/PurchasableVariant";
import React from "react";

import { ProductNameContainer } from "../../../libs";
import { formatProductAndVariantNameForDisplay } from "@smartrr/shared/utils/displayUtils";
import { isDefaultVariant } from "@smartrr/shared/utils/isDefaultVariant";

interface ILineItemDisplayProps {
  purchasable: IPurchasable | undefined;
  vnt: IPurchasableVariant | undefined | null;
  productName: string | undefined;
  variantName: string | undefined;
  isAddOn: boolean;
  isRedeemed: boolean;
  isBundle: boolean;
}

export const LineItemDisplay = ({
  purchasable,
  vnt,
  productName,
  variantName,
  isAddOn,
  isRedeemed,
  isBundle,
}: ILineItemDisplayProps) => {
  const imageUrl = vnt?.purchasableVariantImages?.[0] || purchasable?.purchasableImages?.[0];
  const imageAltText = vnt?.purchasableVariantName || productName || "";

  return (
    <LegacyStack wrap={false} vertical={false} alignment="center">
      {!!imageUrl && <Thumbnail size="small" source={imageUrl} alt={imageAltText} />}
      <ProductNameContainer
        title={`${formatProductAndVariantNameForDisplay({
          purchasableName: productName ?? "",
          purchasableVariantName: variantName,
        })} ${isRedeemed ? "Loyalty redemption" : ""} ${isAddOn ? "One-time add-on" : ""}`}
      >
        {!!productName &&
          (isBundle ? (
            <Text variant="bodyMd" as="p" truncate>
              <Link removeUnderline={true} accessibilityLabel="View Contents of Bundle">
                {productName}
              </Link>
            </Text>
          ) : (
            <Text variant="bodyMd" as="p" truncate>
              {productName}
            </Text>
          ))}
        {!!variantName && !isDefaultVariant(variantName) && (
          <Text variant="bodySm" as="p" color="subdued" truncate>
            {variantName}
          </Text>
        )}
        {isRedeemed ? (
          <Text variant="bodySm" as="p" color="warning" truncate>
            <i>Loyalty redemption</i>
          </Text>
        ) : isAddOn ? (
          <Text variant="bodySm" as="p" color="warning" truncate>
            <i>One-time add-on</i>
          </Text>
        ) : null}
      </ProductNameContainer>
    </LegacyStack>
  );
};
