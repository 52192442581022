import { Modal } from "@shopify/polaris";
import React from "react";

interface ConfirmationWindowProps {
  open: boolean;
  title: string;
  confirmationText: string;
  rejectionText: string;
  onReject: () => void;
  onConfirm: () => void;
  children?: React.ReactNode;
}

export const ConfirmationWindow: React.FC<ConfirmationWindowProps> = ({
  onReject,
  onConfirm,
  rejectionText,
  confirmationText,
  children,
  ...props
}) => {
  return (
    <Modal
      {...props}
      onClose={onReject}
      primaryAction={{
        content: confirmationText,
        onAction: onConfirm,
      }}
      secondaryActions={[
        {
          content: rejectionText,
          onAction: onReject,
        },
      ]}
    >
      {!!children && <Modal.Section>{children}</Modal.Section>}{" "}
    </Modal>
  );
};
